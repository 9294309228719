exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-aguaman-expotachira-2022-mdx": () => import("./../../../src/pages/blog/aguaman-expotachira-2022.mdx" /* webpackChunkName: "component---src-pages-blog-aguaman-expotachira-2022-mdx" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-que-es-aguaman-mdx": () => import("./../../../src/pages/blog/que-es-aguaman.mdx" /* webpackChunkName: "component---src-pages-blog-que-es-aguaman-mdx" */),
  "component---src-pages-ciudades-js": () => import("./../../../src/pages/ciudades.js" /* webpackChunkName: "component---src-pages-ciudades-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-descargar-js": () => import("./../../../src/pages/descargar.js" /* webpackChunkName: "component---src-pages-descargar-js" */),
  "component---src-pages-eliminar-cuenta-js": () => import("./../../../src/pages/eliminar-cuenta.js" /* webpackChunkName: "component---src-pages-eliminar-cuenta-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invitacion-exitosa-js": () => import("./../../../src/pages/invitacion-exitosa.js" /* webpackChunkName: "component---src-pages-invitacion-exitosa-js" */),
  "component---src-pages-invitacion-js": () => import("./../../../src/pages/invitacion.js" /* webpackChunkName: "component---src-pages-invitacion-js" */),
  "component---src-pages-kit-de-inicio-js": () => import("./../../../src/pages/kit-de-inicio.js" /* webpackChunkName: "component---src-pages-kit-de-inicio-js" */),
  "component---src-pages-negocios-js": () => import("./../../../src/pages/negocios.js" /* webpackChunkName: "component---src-pages-negocios-js" */),
  "component---src-pages-pedir-botellon-js": () => import("./../../../src/pages/pedir-botellon.js" /* webpackChunkName: "component---src-pages-pedir-botellon-js" */),
  "component---src-pages-preguntas-frecuentes-js": () => import("./../../../src/pages/preguntas-frecuentes.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-js" */),
  "component---src-pages-primera-recarga-js": () => import("./../../../src/pages/primera-recarga.js" /* webpackChunkName: "component---src-pages-primera-recarga-js" */)
}

